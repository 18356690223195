import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSession } from 'next-auth/react';
import { withPageProtection } from '../components/withPageProtection';
const Dashboard = dynamic(() => import('../views/DashboardV2'));
const SetCurrentUserFirstAndLastNameModal = dynamic(() => import('../views/SetCurrentUserFirstAndLastNameModal'));

const Home = props => {
  const { data: session } = useSession();
  const [showRequestCurrentUserFirstAndLastNameModal, setShowRequestCurrentUserFirstAndLastNameModal] = useState(false);

  useEffect(() => {
    if (session?.user && !session?.user?.name) {
      setShowRequestCurrentUserFirstAndLastNameModal(true);
    }
  }, [session?.user]);

  return (
    <>
      <Dashboard user={session?.user} {...props} />
      {showRequestCurrentUserFirstAndLastNameModal && <SetCurrentUserFirstAndLastNameModal />}
    </>
  );
};

export default withPageProtection(Home);
