import React from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import useMainContext from '../hooks/main-provider';
import { Spinner } from 'reactstrap';
import config from '@cardash/config';
import { getRouteByPath, routeBelongsToViewMode, userHasRoutePermission } from '../utils/auth';
import * as Sentry from '@sentry/nextjs';
import { SentryError } from '../utils/sentry';

export function withPageProtection(Component) {
  return function WrappedComponent(props) {
    const { data: session } = useSession();
    const router = useRouter();
    const { viewMode, viewModeDataLoading } = useMainContext();

    if (viewModeDataLoading) {
      return <Spinner />;
    }

    const { routes } = config;

    if (!routes) {
      Sentry.withScope(function (scope) {
        scope.setTag('404', 'routes not found');
        scope.setTag('routes', routes);
        scope.setExtra('url', router.pathname);

        // capture the event
        Sentry.captureException(new SentryError('User has been navigated to the 404 page'));
      });
      router.push('/404/no-routes', undefined, { shallow: true });
      return null;
    }

    const route = getRouteByPath(routes, router.pathname);
    const userType = session?.user?.type;

    if (!route?.users?.length) {
      if (userType) {
        router.push(routes.home.path);
      }
      return null;
    }

    if (!session?.user?.id) {
      router.push(routes.login.path);
      return null;
    }

    if (!userHasRoutePermission(route, userType) || (!!viewMode && !routeBelongsToViewMode(route, viewMode))) {
      Sentry.withScope(function (scope) {
        scope.setTag('404', 'Permissions issue');
        scope.setTag('viewMode', viewMode);
        scope.setTag('userType', userType);
        scope.setTag('userId', session.user.id);
        scope.setExtra('url', '/');

        // capture the event
        Sentry.captureException(new SentryError('User has been navigated to the 404 page'));
      });
      router.push(`/404/${userType}/${viewMode}`, undefined, { shallow: true });
      return null;
    }

    return <Component {...props} />;
  };
}
